import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { TrackingEvent } from '../types/tracking-events';

type TrackEventFn = (
    name: TrackingEvent,
    customProperties?: { [key: string]: string | number | undefined }
) => void;

export const useTrackEvent = (): TrackEventFn => {
    const appInsights = useAppInsightsContext();

    return (name, customProperties) => {
        appInsights.trackEvent({
            name,
            properties: customProperties,
        });
    };
};
