import './index.css';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { AppInsightsProvider } from './custom-providers/AppInsightsProvider';
import { ConfigProvider } from './custom-providers/ConfigProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { GrowthbookProvider } from './custom-providers/GrowthbookProvider';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { lightTheme } from '@cognassist/react-components';
import reportWebVitals from './reportWebVitals';
import { router } from './router';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={lightTheme}>
                <SnackbarProvider>
                    <CssBaseline />
                    <ConfigProvider>
                        <GrowthbookProvider>
                            <AppInsightsProvider>
                                <RouterProvider router={router} />
                            </AppInsightsProvider>
                        </GrowthbookProvider>
                    </ConfigProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
