import { useEffect, useState } from 'react';

const DEFAULT_STATE = {
    passwordLength: false,
    lowercase: false,
    uppercase: false,
    number: false,
};

export const usePasswordStrength = () => {
    const [passwordStrength, setPasswordStrength] = useState<number>(0);
    const [newPasswordStrength, setNewPasswordStrength] =
        useState(DEFAULT_STATE);
    const [newValidPassword, setNewValidPassword] = useState(false);

    useEffect(() => {
        setNewValidPassword(
            Object.values(newPasswordStrength).every((val) => val === true)
        );
    }, [newPasswordStrength]);

    const handlePasswordStrength = (value: string) => {
        setPasswordStrength(1);
        if (value.length >= 8) {
            if (value.match(/(?=.*[a-z])/)) {
                setPasswordStrength((prevState) => prevState + 1);
            }

            if (value.match(/(?=.*[A-Z])/)) {
                setPasswordStrength((prevState) => prevState + 1);
            }

            if (value.match(/(?=.*\d)/)) {
                setPasswordStrength((prevState) => prevState + 1);
            }

            if (value.match(/(?=.*[!@#$%^&*.])/)) {
                setPasswordStrength((prevState) => prevState + 1);
            }
        }
    };

    const newHandlePasswordStrength = (value: string) => {
        setNewPasswordStrength(DEFAULT_STATE);
        if (value.length >= 8) {
            setNewPasswordStrength((prevState) => ({
                ...prevState,
                passwordLength: true,
            }));
        }

        if (value.match(/(?=.*[a-z])/)) {
            setNewPasswordStrength((prevState) => ({
                ...prevState,
                lowercase: true,
            }));
        }

        if (value.match(/(?=.*[A-Z])/)) {
            setNewPasswordStrength((prevState) => ({
                ...prevState,
                uppercase: true,
            }));
        }

        if (value.match(/(?=.*\d)/)) {
            setNewPasswordStrength((prevState) => ({
                ...prevState,
                number: true,
            }));
        }
    };

    return {
        handlePasswordStrength,
        newHandlePasswordStrength,
        passwordStrength,
        newPasswordStrength,
        newValidPassword,
    };
};
