import Loading from '../pages/Loading';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import { useGrowthBook } from '@growthbook/growthbook-react';

const MainWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100vh',
    background: theme.palette.background.default,
    display: 'flex',
    zIndex: 1,
}));

export const Layout = () => {
    const gb = useGrowthBook();

    if (!gb?.ready) {
        return <Loading />;
    }

    return (
        <MainWrapper>
            <Outlet />
        </MainWrapper>
    );
};
