import {
    BottomNav,
    Carousel,
    Form,
    Header,
    Input,
    InputLabel,
    InputLabelWrapper,
    InputWrapper,
    Logo,
    ShowPasswordButton,
    SignUpButton,
    Signup,
    SubHeader,
    Wrapper,
} from './styled-components';

import CogLogo from '../../assets/img/logo.svg';
import { NewPasswordStrength } from '../../components/register/NewPasswordStrength';
import { RegisterCarousel } from './Carousel';
import { TermsConditions } from './TermsConditions';
import { LegalDocumentDialog } from './LegalDocumentDialog';
import { useEndpoints } from '../../custom-hooks/useEndpoints';
import { usePasswordStrength } from '../../custom-hooks/usePasswordStrength';
import { useTrackEvent } from '../../custom-hooks/useTrackEvent';
import { TrackingEvent } from '../../types/tracking-events';
import { ClientAccountType, LegalDocumentType } from '../../types/enums';

import { useState, useMemo, useEffect } from 'react';

type Dialog = 'termsAndConditions' | 'privacyPolicy';

interface IOwnProps {
    loginId: string;
    registrationCode: string;
    clientDetails: CogApi.ClientDetails | null;
}

export const RegisterNew: React.FC<IOwnProps> = ({
    loginId,
    registrationCode,
    clientDetails,
}) => {
    const { newPasswordStrength, newHandlePasswordStrength, newValidPassword } =
        usePasswordStrength();
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
        useState<boolean>(false);
    const [termsAndConditionsError, setTermsAndConditionsError] =
        useState<boolean>(false);
    const [currentDialog, setCurrentDialog] = useState<Dialog | null>(null);
    const [legalDocuments, setLegalDocuments] = useState<
        CogApi.ILegalDocument[]
    >([]);

    const { setNewPassword, getLegalDocuments } = useEndpoints();

    const trackEvent = useTrackEvent();

    const mustAcceptTermsAndConditions =
        clientDetails?.clientAccountType === ClientAccountType.Enterprise;

    useEffect(() => {
        const getLegalDocumentsAsync = async () => {
            const { data } = await getLegalDocuments();

            if (data) {
                setLegalDocuments(data.latestLegalDocuments);
            } else {
                setLegalDocuments([]);
            }
        };

        if (mustAcceptTermsAndConditions) {
            getLegalDocumentsAsync();
        }
    }, [mustAcceptTermsAndConditions]);

    const parsedLegalDocuments = useMemo(() => {
        const getDocument = (type: LegalDocumentType) => {
            return legalDocuments.find((d) => d.legalDocumentType === type);
        };

        return [
            {
                name: 'Terms and Conditions',
                dialogOpen: currentDialog === 'termsAndConditions',
                openDialog: () =>
                    openDialog(
                        'termsAndConditions',
                        TrackingEvent.TERMS_AND_CONDITIONS_OPENED
                    ),
                closeDialog: () =>
                    closeDialog(TrackingEvent.TERMS_AND_CONDITIONS_CLOSED),
                scrollTrackingEvent:
                    TrackingEvent.TERMS_AND_CONDITIONS_BOTTOM_SCROLL,
                ...getDocument(LegalDocumentType.TermsAndConditions),
            },
            {
                name: 'Privacy Policy',
                dialogOpen: currentDialog === 'privacyPolicy',
                openDialog: () =>
                    openDialog(
                        'privacyPolicy',
                        TrackingEvent.PRIVACY_POLICY_OPENED
                    ),
                closeDialog: () =>
                    closeDialog(TrackingEvent.PRIVACY_POLICY_CLOSED),
                scrollTrackingEvent: TrackingEvent.PRIVACY_POLICY_BOTTOM_SCROLL,
                ...getDocument(LegalDocumentType.PrivacyPolicy),
            },
        ];
    }, [legalDocuments, currentDialog]);

    const openDialog = (dialog: Dialog, trackingEvent: TrackingEvent) => {
        setCurrentDialog(dialog);
        trackEvent(trackingEvent, {
            loginId,
        });
    };

    const closeDialog = (trackingEvent: TrackingEvent) => {
        setCurrentDialog(null);
        trackEvent(trackingEvent, {
            loginId,
        });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevState) => !prevState);
    };

    const submitPassword = async () => {
        if (!acceptTermsAndConditions) {
            setTermsAndConditionsError(true);
            return;
        }

        if (newValidPassword && acceptTermsAndConditions) {
            setTermsAndConditionsError(false);

            const { data } = await setNewPassword({
                data: {
                    password,
                    registrationCode,
                    loginId,
                    legalDocumentIds: legalDocuments.map(
                        (legalDocument) => legalDocument.id
                    ),
                },
                token: registrationCode,
            });

            if (data) {
                window.location.href = data.loginUrl;
            }
        }
    };

    return (
        <Wrapper>
            <Signup>
                <Logo src={CogLogo} alt='Cognassist logo' />
                <Form>
                    <Header>Welcome to Cognassist.</Header>
                    {clientDetails?.clientName && (
                        <SubHeader>
                            You&apos;ve been invited by{' '}
                            {clientDetails?.clientName}.
                        </SubHeader>
                    )}
                    <InputWrapper>
                        <InputLabelWrapper>
                            <InputLabel htmlFor='password'>Password</InputLabel>
                            {password && (
                                <ShowPasswordButton
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible
                                        ? 'Hide password'
                                        : 'Show password'}
                                </ShowPasswordButton>
                            )}
                        </InputLabelWrapper>
                        <Input
                            id='password'
                            fullWidth
                            type={passwordVisible ? 'text' : 'password'}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                newHandlePasswordStrength(e.target.value);
                                setPassword(e.target.value);
                            }}
                        />
                        <NewPasswordStrength
                            passwordStrength={newPasswordStrength}
                            value={password}
                        />
                        {mustAcceptTermsAndConditions && (
                            <TermsConditions
                                legalDocuments={parsedLegalDocuments}
                                acceptTermsAndConditions={
                                    acceptTermsAndConditions
                                }
                                setAcceptTermsAndConditions={
                                    setAcceptTermsAndConditions
                                }
                                termsAndConditionsError={
                                    termsAndConditionsError
                                }
                            />
                        )}
                    </InputWrapper>
                    <SignUpButton
                        size='large'
                        variant='contained'
                        disabled={newValidPassword ? false : true}
                        onClick={submitPassword}
                    >
                        Sign up
                    </SignUpButton>
                </Form>
                <BottomNav>
                    <a
                        href='https://cognassist.com/privacy-policy'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Privacy Policy
                    </a>
                    <a
                        href='https://cognassist.force.com/support/s/?language=en_US'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Support
                    </a>
                    <p>&#169; Cognassist {new Date().getFullYear()}</p>
                </BottomNav>
            </Signup>
            <Carousel>
                <RegisterCarousel />
            </Carousel>
            {mustAcceptTermsAndConditions &&
                parsedLegalDocuments.map((document) => (
                    <LegalDocumentDialog
                        key={document.name}
                        document={document}
                        onBottomScroll={() =>
                            trackEvent(document.scrollTrackingEvent, {
                                loginId,
                            })
                        }
                    />
                ))}
        </Wrapper>
    );
};
