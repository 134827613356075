import { useEffect, useState } from 'react';

import { ClientAccountType } from '../../types/enums';
import Cogfetti from '../../components/Cogfetti';
import InvalidToken from '../InvalidToken';
import Loading from '../Loading';
import Register from '../Register';
import { RegisterNew } from '../RegisterNew';
import { styled } from '@mui/material';
import { useEndpoints } from '../../custom-hooks/useEndpoints';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useParams } from 'react-router-dom';

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100vh',
    display: 'flex',
    zIndex: 1,
    background: theme.palette.grey[100],
}));

export const ValidateToken: React.FC = () => {
    const [tokenIsValid, setTokenIsValid] = useState<boolean>(false);
    const [registrationCode, setRegistrationCode] = useState<string>('');
    const [loginId, setLoginId] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [clientDetails, setClientDetails] =
        useState<CogApi.ClientDetails | null>(null);

    const { token } = useParams<{ token: string }>();
    const newCredentialsPage = useFeatureIsOn('credentials-page');
    const { getRegistrationInvites } = useEndpoints();
    const canSeeNewRegistration =
        newCredentialsPage &&
        clientDetails?.clientAccountType === ClientAccountType.Enterprise;

    useEffect(() => {
        const validateRegistrationCode = async () => {
            if (token) {
                const { data } = await getRegistrationInvites(token);

                if (data?.isRegistered === true) {
                    window.location.href = data.loginUrl;
                } else if (data?.loginId) {
                    setTokenIsValid(true);
                    setLoginId(data.loginId);
                    setRegistrationCode(token);
                    setClientDetails({
                        clientName: data.clientName,
                        clientAccountType: data.clientAccountType,
                    });
                }
            }

            setLoading(false);
        };
        validateRegistrationCode();
    }, []);

    if (loading) {
        return (
            <Wrapper>
                <Loading />
                <Cogfetti />
            </Wrapper>
        );
    }

    if (tokenIsValid) {
        return canSeeNewRegistration ? (
            <RegisterNew
                registrationCode={registrationCode}
                loginId={loginId}
                clientDetails={clientDetails}
            />
        ) : (
            <Wrapper>
                <Register
                    registrationCode={registrationCode}
                    loginId={loginId}
                />
            </Wrapper>
        );
    }

    return <InvalidToken />;
};

export default ValidateToken;
