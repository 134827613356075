import { Check as FeatherCheck, X as FeatherCross } from 'react-feather';

import { pxToRem } from '../../utils/pxToRem';
import { shouldForwardProps } from '../../utils/shouldForwardProp';
import { styled } from '@mui/material';

const IsValidItem = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    '& p': {
        margin: 0,
        [theme.breakpoints.down(744)]: {
            fontSize: pxToRem(14),
            lineHeight: pxToRem(15),
        },
    },
}));

const FeedbackCircle = styled('div', {
    ...shouldForwardProps('backgroundColor'),
})<{ backgroundColor?: string }>(({ backgroundColor }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    width: 24,
    backgroundColor: backgroundColor,
    borderRadius: '50px',
    marginRight: '8px',
}));

interface IOwnProps {
    passwordStrength: Hooks.INewPasswordStrength;
    value: string;
}

export const NewPasswordStrength: React.FC<IOwnProps> = ({
    passwordStrength,
    value,
}) => {
    return (
        <div id='validation'>
            <IsValidItem>
                <CheckValid
                    isValid={passwordStrength.passwordLength}
                    value={value}
                />
                <p>At least 8 characters long.</p>
            </IsValidItem>
            <IsValidItem>
                <CheckValid
                    isValid={passwordStrength.lowercase}
                    value={value}
                />
                <p>At least one lowercase letter (a-z).</p>
            </IsValidItem>
            <IsValidItem>
                <CheckValid
                    isValid={passwordStrength.uppercase}
                    value={value}
                />
                <p>At least one upper case letter (A-Z).</p>
            </IsValidItem>
            <IsValidItem>
                <CheckValid isValid={passwordStrength.number} value={value} />
                <p>At least one number (0-9).</p>
            </IsValidItem>
        </div>
    );
};

const CheckValid: React.FC<{ isValid: boolean; value: string }> = ({
    isValid,
    value,
}) => {
    if (value.length === 0) {
        return <FeedbackCircle backgroundColor='#CFD8E6' />;
    }
    return isValid ? (
        <FeedbackCircle backgroundColor='#7BAE02'>
            <FeatherCheck color='#fff' size={18} />
        </FeedbackCircle>
    ) : (
        <FeedbackCircle backgroundColor='#CC0000'>
            <FeatherCross color='#fff' size={18} />
        </FeedbackCircle>
    );
};
