export const getBaseUrl = (): string => {
    switch (window.location.hostname) {
        case '127.0.0.1':
            return `http://127.0.0.1:60180/`;
        case 'localhost':
            return `http://localhost:60180/`;
        // return 'https://qamy.cognassist.com/bff';
        default:
            return `${window.location.origin}/account-bff`;
    }
};
