import { useState, useEffect, useCallback, RefObject } from 'react';

export const useHasScrolledToBottom = (divRef: RefObject<HTMLDivElement>) => {
    const [isOnBottom, setIsOnBottom] = useState(false);

    const handleScroll = useCallback(() => {
        const divElement = divRef?.current;

        if (!divElement) {
            return;
        }

        const { scrollTop, scrollHeight, clientHeight } = divElement;

        if (scrollTop + clientHeight === scrollHeight) {
            setIsOnBottom(true);
        } else {
            setIsOnBottom(false);
        }
    }, [divRef]);

    useEffect(() => {
        const divElement = divRef?.current;

        if (!divElement) {
            return;
        }

        divElement.addEventListener('scroll', handleScroll);

        return () => {
            divElement.removeEventListener('scroll', handleScroll);
        };
    }, [divRef, handleScroll]);

    return isOnBottom;
};
