import { Typography, styled } from '@mui/material';

import Footer from '../../components/footer';
import logo from '../../assets/img/logo.svg';

const Wrapper = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100vh',
    display: 'flex',
    zIndex: 1,
    background: theme.palette.grey[100],
}));

const InvalidTokenBox = styled('div')(({ theme }) => ({
    width: '90%',
    maxWidth: 450,
    margin: 'auto',
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: theme.cogShadows.base,
    padding: theme.spacing(5),
    display: 'grid',
    zIndex: 2,
}));

const Logo = styled('img')(() => ({
    width: 120,
    height: 36,
}));

const Heading = styled('h1')(() => ({
    marginBottom: 16,
    fontSize: 24,
}));

const Support = styled('a')(({ theme }) => ({
    fontWeight: 700,
    color: theme.palette.primary.main,
    textDecoration: 'none',
}));

export const InvalidToken: React.FC = () => {
    return (
        <Wrapper>
            <InvalidTokenBox>
                <Logo src={logo} alt='logo' />
                <Heading>Whoops, something went wrong.</Heading>
                <Typography>
                    It appears you don&apos;t have a valid registration token.
                </Typography>
                <Typography>
                    Please contact{' '}
                    <Support href='mailto:support@cognassist.com'>
                        support@cognassist.com
                    </Support>
                </Typography>
            </InvalidTokenBox>
            <Footer />
        </Wrapper>
    );
};

export default InvalidToken;
