import { Button, Input } from '@cognassist/react-components';
import { createStyles, makeStyles } from '@mui/styles';

import Cogfetti from '../../components/Cogfetti';
import ErrorIcon from '@mui/icons-material/Error';
import { Theme } from '@mui/material';
import { Wrapper } from '../../styled-components/shared';
import logo from '../../assets/img/logo.svg';
import { useEndpoints } from '../../custom-hooks/useEndpoints';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        forgottenDetailsForm: {
            width: '90%',
            maxWidth: 450,
            margin: 'auto',
            background: theme.palette.background.paper,
            borderRadius: 4,
            boxShadow: theme.cogShadows.base,
            padding: 40,
            display: 'grid',
            zIndex: 2,
        },
        forgottenDetailsLogo: {
            width: 120,
            height: 36,
        },
        forgottenDetailsHeading: {
            marginBottom: 16,
            fontSize: 16,
        },
        emailInput: {
            '& input': {
                width: '100%',
                '&::-ms-reveal': {
                    display: 'none !important',
                },
            },
            '& div': {
                marginLeft: 0,
            },
        },
        errorContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 16,
        },
        errorSpan: {
            color: theme.palette.error.main,
            fontWeight: 700,
        },
        errorIcon: {
            fill: theme.palette.error.main,
            marginRight: 8,
        },
        locateEmailButton: {
            margin: '24px 0',
            fontFamily: 'FS Me',
        },
    })
);

export const ForgottenLogin: React.FC = () => {
    const [requestError, setRequestError] = useState('');
    const [email, setEmail] = useState('');
    const classes = useStyles();
    const { validateEmail } = useEndpoints();

    const handlePassword = (e: HTMLInputElement) => {
        setEmail(e.value);
    };

    const submitEmail = (
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        validateEmailRequest();
    };

    const validateEmailRequest = async () => {
        const { data, error } = await validateEmail(email);

        if (data) {
            window.location.href = data.callbackUri;
        }

        if (error) {
            setRequestError('Something has gone wrong, ');
        }
    };

    return (
        <Wrapper>
            <form
                className={classes.forgottenDetailsForm}
                onSubmit={(e) => submitEmail(e)}
            >
                <img
                    className={classes.forgottenDetailsLogo}
                    src={logo}
                    alt='logo'
                />
                <h2 className={classes.forgottenDetailsHeading}>
                    Hello there, please enter your email and you will be
                    redirected to the correct login page.
                </h2>
                <Input
                    className={classes.emailInput}
                    placeholder='Email Address'
                    autoFocus={true}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handlePassword(e.target);
                    }}
                ></Input>

                {requestError && (
                    <div className={classes.errorContainer}>
                        <ErrorIcon className={classes.errorIcon} />
                        <span className={classes.errorSpan}>
                            {requestError}
                        </span>
                    </div>
                )}

                <Button
                    className={classes.locateEmailButton}
                    size='large'
                    onClick={(e) => submitEmail(e)}
                    text='Find Login Details'
                />
            </form>
            <Cogfetti />
        </Wrapper>
    );
};

export default ForgottenLogin;
