import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';

import ForgottenLogin from './pages/ForgottenLogin';
import InvalidToken from './pages/InvalidToken';
import { Layout } from './components/Layout';
import { Login as LoginNew } from './pages/LoginNew';
import { NotFound } from './pages/NotFound';
import { SelfOnboardingRedirect } from './pages/SelfOnboardingRedirect';
import ValidateToken from './pages/ValidateToken';

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/account' element={<Layout />}>
            <Route path='register'>
                <Route index element={<InvalidToken />} />
                <Route path=':token' element={<ValidateToken />} />
            </Route>
            <Route path='loginhelp' element={<ForgottenLogin />} />
            <Route path='login' element={<LoginNew />} />
            <Route
                path='signup/:friendlyId'
                element={<SelfOnboardingRedirect />}
            />
            <Route path='*' element={<NotFound />} />
        </Route>
    )
);
