import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export interface IAPIError {
    detail: string;
    errors: {
        [key: string]: string[];
    };
    instance: string;
    status: number;
    title: string;
    type: string;
}

export interface IUseApiFn {
    <R, T = unknown>(config: AxiosRequestConfig<T>): Promise<{
        error: AxiosError<IAPIError> | Error | null;
        data: R | null;
    }>;
}

interface IUseApiResponse {
    callApi: IUseApiFn;
}

interface IOwnProps {
    baseURL: string;
}

export const useApi = ({ baseURL }: IOwnProps): IUseApiResponse => {
    const callApi: IUseApiFn = async (config: AxiosRequestConfig) => {
        const requestConfig = {
            ...config,
            baseURL,
        };

        try {
            const res = await axios.request(requestConfig);
            return {
                data: res.data,
                error: null,
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    error: error as AxiosError<IAPIError>,
                    data: null,
                };
            }

            return {
                error: error as Error,
                data: null,
            };
        }
    };

    return {
        callApi,
    };
};
