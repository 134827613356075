import Logo from '../../assets/img/logo.svg';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    loadingWrapper: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
    },
    logo: {
        animation: `$flashEffect 1000ms ${theme.transitions.easing.sharp} infinite`,
        maxWidth: 220,
    },
    '@keyframes flashEffect': {
        '0%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.1)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
}));

const Loading: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.loadingWrapper}>
            <img className={classes.logo} src={Logo} alt='loading' />
        </div>
    );
};

export default Loading;
