import './carousel.css';

import { CSSProperties, useCallback } from 'react';
import { Carousel, CarouselProps } from 'react-responsive-carousel';
import {
    CarouselArrow,
    CarouselHeading,
    CarouselImage,
    CarouselItem,
    CarouselText,
    CarouselWrapper,
} from './styled-components';
import { ChevronLeft, ChevronRight } from 'react-feather';

import Carousel1 from '../../assets/img/carousel1.png';
import Carousel2 from '../../assets/img/carousel2.png';
import Carousel3 from '../../assets/img/carousel3.png';

export const RegisterCarousel = () => {
    const indicatorStyles: CSSProperties = {
        background: '#fff',
        width: 8,
        height: 8,
        display: 'inline-block',
        margin: '0 8px',
        borderRadius: 50,
        cursor: 'pointer',
    };

    const CustomIndicator: CarouselProps['renderIndicator'] = useCallback(
        (onClickHandler, isSelected, index, label) => {
            if (isSelected) {
                return (
                    <li
                        style={{
                            ...indicatorStyles,
                            background: '#039FE0',
                            cursor: 'auto',
                        }}
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                    />
                );
            }

            return (
                <li
                    style={indicatorStyles}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role='button'
                    tabIndex={0}
                    title={`${label} ${index + 1}`}
                    aria-label={`${label} ${index + 1}`}
                />
            );
        },
        []
    );

    return (
        <CarouselWrapper>
            <Carousel
                showArrows
                showThumbs={false}
                renderIndicator={CustomIndicator}
                showStatus={false}
                autoPlay
                interval={15000}
                infiniteLoop
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <CarouselArrow
                            type='button'
                            onClick={onClickHandler}
                            title={label}
                            style={{ left: 50 }}
                        >
                            <ChevronLeft color='#33383F' />
                        </CarouselArrow>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <CarouselArrow
                            type='button'
                            onClick={onClickHandler}
                            title={label}
                            style={{ right: 50 }}
                        >
                            <ChevronRight color='#33383F' />
                        </CarouselArrow>
                    )
                }
            >
                <CarouselItem>
                    <CarouselHeading>Why Cognassist?</CarouselHeading>
                    <CarouselImage src={Carousel1} alt='' />
                    <CarouselText>
                        You have been invited to sign up to the Cognassist
                        platform to discover unique insights about how you
                        think, work and process information best.
                    </CarouselText>
                </CarouselItem>
                <CarouselItem>
                    <CarouselHeading>Who is Cognassist?</CarouselHeading>
                    <CarouselImage src={Carousel2} alt='' />
                    <CarouselText>
                        Cognassist provides a multi-award winning neurodiversity
                        solution comprising a cognitive assessment, personalised
                        report and ongoing learning.
                    </CarouselText>
                </CarouselItem>
                <CarouselItem>
                    <CarouselHeading>Cognassist benefits</CarouselHeading>
                    <CarouselImage src={Carousel3} alt='' />
                    <CarouselText>
                        Learn how to harness your cognitive strengths, overcome
                        challenges and increase your neurodiversity awareness.
                    </CarouselText>
                </CarouselItem>
            </Carousel>
        </CarouselWrapper>
    );
};
