import {
    Button as MuiButton,
    Tooltip as MuiTooltip,
    styled,
} from '@mui/material';

import { HelpCircle } from 'react-feather';
import { ReactElement } from 'react';
import { pxToRem } from '../utils/pxToRem';
import { shouldForwardProps } from '../utils/shouldForwardProp';

const Wrapper = styled('div')(() => ({
    marginBottom: 24,
    width: '100%',
    '&:last-child': {
        marginBottom: 0,
    },
}));

const Required = styled('p', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(24),
    fontWeight: 'bold',
    marginLeft: 8,
    color: hasError ? theme.palette.error.main : 'inherit',
    [theme.breakpoints.down(744)]: {
        display: 'none',
    },
}));

const Label = styled('label', {
    ...shouldForwardProps('hasError'),
})<{ hasError?: boolean }>(({ hasError, theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 'normal',
    color: hasError ? theme.palette.error.main : 'inherit',
}));

const FieldWrapper = styled('div', {
    ...shouldForwardProps('hide'),
})<{ hide?: boolean }>(({ theme, hide }) => ({
    display: hide ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
}));

const Tooltip = styled(MuiTooltip)(({ theme }) => ({
    [theme.breakpoints.up(744)]: {
        display: 'none',
    },
}));

const TooltipContent = styled('div')(() => ({
    padding: '16px 8px',
}));

const TooltipTitle = styled('h1')(({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    color: theme.palette.common.white,
    marginBottom: 16,
}));

const TooltipText = styled('p')(({ theme }) => ({
    fontSize: pxToRem(12),
    lineHeight: '150%',
    color: theme.palette.common.white,
}));

const Button = styled(MuiButton)(() => ({
    textTransform: 'none',
}));

interface IOwnProps {
    label: string;
    children: ReactElement;
    required?: boolean;
    labelFor?: string;
    labelId?: string;
    hasError?: boolean;
    content?: {
        title: string;
        text: string;
    };
    hide?: boolean;
    inputAction?: {
        name: string;
        action: () => void;
    };
    hideTooltip?: boolean;
}

export const Field: React.FC<IOwnProps> = ({
    label,
    children,
    required = false,
    labelFor,
    labelId,
    hasError,
    content,
    hide,
    inputAction,
    hideTooltip,
}) => {
    const dynamicProps: {
        htmlFor?: string;
        id?: string;
        hasError?: boolean;
    } = {
        hasError,
    };

    if (labelFor) {
        dynamicProps.htmlFor = labelFor;
    }

    if (labelId) {
        dynamicProps.id = labelId;
    }

    return (
        <Wrapper>
            <FieldWrapper hide={hide}>
                <Label {...dynamicProps}>{label}</Label>
                {required && <Required hasError={hasError}>Required</Required>}
                {inputAction && (
                    <Button onClick={inputAction.action} color='inherit'>
                        {inputAction.name}
                    </Button>
                )}
                {!hideTooltip && content && (
                    <Tooltip
                        title={
                            <TooltipContent>
                                <TooltipTitle>{content?.title}</TooltipTitle>
                                <TooltipText>{content?.text}</TooltipText>
                            </TooltipContent>
                        }
                        arrow
                        enterTouchDelay={0}
                        leaveTouchDelay={10000}
                    >
                        <HelpCircle size={14} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                )}
            </FieldWrapper>

            {children}
        </Wrapper>
    );
};
