import { createStyles, makeStyles } from '@mui/styles';

import LinearProgress from '@mui/material/LinearProgress';
import PasswordStrengthLabel from './PasswordStrengthLabel';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        passwordStrengthBarContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 12,
        },
        passwordStrengthBarContainerHidden: {
            display: 'none',
        },
        passwordStrengthBar: {
            flexBasis: '100%',
            height: 8,
            marginRight: 23,
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.grey[50],
            '&[data-strength="1"] span': {
                background: theme.palette.error.main,
            },
            '&[data-strength="2"] span': {
                background: theme.palette.error.main,
            },
            '&[data-strength="3"] span': {
                background: theme.palette.warning.main,
            },
            '&[data-strength="4"] span': {
                background: theme.palette.success.main,
            },
            '&[data-strength="5"] span': {
                background: theme.palette.success.main,
            },
        },
        passwordStrengthBarLabel: {
            fontWeight: 500,
            fontSize: 12,
            color: theme.palette.text.primary,
            flexShrink: 0,
        },
        passwordStrengthBarSpan: {
            fontWeight: 700,
        },
    })
);

interface IOwnProps {
    showPasswordStrength: boolean;
    passwordStrength: number;
}

export const PasswordStrength: React.FC<IOwnProps> = ({
    showPasswordStrength,
    passwordStrength,
}) => {
    const classes = useStyles();

    return (
        <div
            className={`${classes.passwordStrengthBarContainer} ${
                showPasswordStrength
                    ? ''
                    : classes.passwordStrengthBarContainerHidden
            }`}
            data-strength={passwordStrength}
        >
            <LinearProgress
                className={classes.passwordStrengthBar}
                variant='determinate'
                value={(passwordStrength * 100) / 5}
                data-strength={passwordStrength}
            />
            <PasswordStrengthLabel passwordStrength={passwordStrength} />
        </div>
    );
};

export default PasswordStrength;
