export enum ClientAccountType {
    AppsAndEd = 1,
    Enterprise,
}

export enum LegalDocumentType {
    PrivacyPolicy = 1,
    TermsAndConditions = 2,
}

export enum AssessmentResultStatus {
    NotSet = 10,
    NoNeedIdentified = 20,
    Matched = 30,
    MonitorAndReview = 40,
}
