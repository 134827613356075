import { Button, Input } from '@cognassist/react-components';
import { createStyles, makeStyles } from '@mui/styles';

import ErrorIcon from '@mui/icons-material/Error';
import Footer from '../../components/footer';
import LoadingIcon from '../../assets/img/loading.svg';
import PasswordStrength from '../../components/register/PasswordStrength';
import { Theme } from '@mui/material';
import logo from '../../assets/img/logo.svg';
import { useEndpoints } from '../../custom-hooks/useEndpoints';
import { usePasswordStrength } from '../../custom-hooks/usePasswordStrength';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        registerForm: {
            width: '90%',
            maxWidth: 450,
            margin: 'auto',
            background: theme.palette.background.paper,
            borderRadius: 16,
            boxShadow: theme.cogShadows.base,
            padding: 40,
            display: 'grid',
            zIndex: 2,
        },
        registerLogo: {
            width: 120,
            height: 36,
        },
        registerHeading: {
            marginBottom: 16,
            fontSize: 24,
        },
        registerRules: {
            fontSize: 16,
            fontWeight: 400,
            margin: '0 0 24px 0',
        },
        inputAdornments: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        passwordLabel: {
            fontWeight: 700,
            fontSize: 16,
            color: theme.palette.text.primary,
            marginBottom: 16,
        },
        showPassword: {
            fontWeight: 700,
            fontSize: 12,
            color: theme.palette.text.primary,
            marginBottom: 16,
            cursor: 'pointer',
            userSelect: 'none',
        },
        passwordInput: {
            '& input': {
                width: '100%',
                '&::-ms-reveal': {
                    display: 'none !important',
                },
            },
            '& div': {
                marginLeft: 0,
            },
        },
        errorContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 16,
        },
        errorSpan: {
            color: theme.palette.error.main,
            fontWeight: 700,
        },
        errorIcon: {
            fill: theme.palette.error.main,
            marginRight: 8,
        },
        registerButton: {
            margin: '24px 0',
            fontFamily: 'FS Me',
        },
        buttonLoadingIcon: {
            animation: `$spin 1000ms linear infinite`,
            transformOrigin: '48%',
        },
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '100%': {
                transform: 'rotate(360deg)',
            },
        },
    })
);

interface IOwnProps {
    loginId: string;
    registrationCode: string;
}

export const Register: React.FC<IOwnProps> = ({
    loginId,
    registrationCode,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordStrength, setShowPasswordStrength] = useState(false);
    const [password, setPassword] = useState('');
    const [sendingPassword, setSendingPassword] = useState(false);
    const [requestError, setRequestError] = useState('');
    const { handlePasswordStrength, passwordStrength } = usePasswordStrength();

    const { setNewPassword } = useEndpoints();

    const handlePassword = (e: HTMLInputElement) => {
        setPassword(e.value);
    };

    const submitPassword = (
        e:
            | React.FormEvent<HTMLFormElement>
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (passwordStrength >= 4) {
            setSendingPassword(true);
            setPasswordRequest();
        }
    };

    const setPasswordRequest = async () => {
        const { data, error } = await setNewPassword({
            data: {
                password,
                registrationCode,
                loginId,
                legalDocumentIds: [],
            },
            token: registrationCode,
        });

        if (data) {
            window.location.href = data.loginUrl;
        }

        if (error) {
            setRequestError('Something has gone wrong, ');
        }

        setSendingPassword(false);
    };

    const showPasswordToggle = () => {
        setShowPassword((prevState) => !prevState);
    };

    const togglePasswordStrength = (value: string) => {
        if (value.length) {
            setShowPasswordStrength(true);
        } else {
            setShowPasswordStrength(false);
            setRequestError('');
        }
    };

    const classes = useStyles();

    const loadingProps = sendingPassword
        ? {
              startIcon: (
                  <img
                      src={LoadingIcon}
                      className={classes.buttonLoadingIcon}
                  />
              ),
              disabled: true,
          }
        : {};

    return (
        <>
            <form
                className={classes.registerForm}
                onSubmit={(e) => submitPassword(e)}
            >
                <img className={classes.registerLogo} src={logo} alt='logo' />
                <h2 className={classes.registerHeading}>
                    Hello there, you need to set a password.
                </h2>
                <p className={classes.registerRules}>
                    Your password must be at least 8 characters and contain
                    letters, numbers and symbols.
                </p>
                <div className={classes.inputAdornments}>
                    <label className={classes.passwordLabel}>Password</label>
                    <span
                        data-testid='showPasswordToggle'
                        className={classes.showPassword}
                        onClick={showPasswordToggle}
                    >
                        {showPassword ? 'Hide ' : 'Show '}
                        password
                    </span>
                </div>

                <Input
                    data-testid='passwordInput'
                    className={classes.passwordInput}
                    data-type={showPassword ? 'text' : 'password'}
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handlePasswordStrength(e.target.value);
                        togglePasswordStrength(e.target.value);
                        handlePassword(e.target);
                    }}
                ></Input>

                <PasswordStrength
                    showPasswordStrength={showPasswordStrength}
                    passwordStrength={passwordStrength}
                />

                {requestError && (
                    <div className={classes.errorContainer}>
                        <ErrorIcon className={classes.errorIcon} />
                        <span className={classes.errorSpan}>
                            {requestError}
                        </span>
                    </div>
                )}

                <Button
                    className={classes.registerButton}
                    size='large'
                    disabled={passwordStrength >= 4 ? false : true}
                    {...loadingProps}
                    onClick={(e) => submitPassword(e)}
                    text='Save Password'
                />
            </form>
            <Footer />
        </>
    );
};

export default Register;
