import {
    Dialog,
    DialogActions,
    DialogActionsWrapper,
    DialogCloseButton,
    DialogTitle,
} from './styled-components';

import { Button } from '@cognassist/react-components';
import CloseIcon from '@mui/icons-material/Close';
import { LegalDocumentDialogContent } from './LegalDocumentDialogContent';
import { LegalDocumentType } from '../../types/enums';
import { TrackingEvent } from '../../types/tracking-events';

type LegalDocument = {
    id?: string;
    body?: string;
    legalDocumentType?: LegalDocumentType;
    name: string;
    dialogOpen: boolean;
    openDialog: () => void;
    closeDialog: () => void;
    scrollTrackingEvent: TrackingEvent;
};

interface IOwnProps {
    document: LegalDocument;
    onBottomScroll: () => void;
}

export const LegalDocumentDialog: React.FC<IOwnProps> = ({
    document,
    onBottomScroll,
}) => (
    <Dialog
        open={document.dialogOpen}
        onClose={document.closeDialog}
        scroll='paper'
        aria-labelledby={`${document.legalDocumentType}-dialog-title`}
    >
        <DialogTitle
            component='h1'
            id={`${document.legalDocumentType}-dialog-title`}
        >
            {document.name}
            <DialogCloseButton
                aria-label='close'
                onClick={document.closeDialog}
            >
                <CloseIcon />
            </DialogCloseButton>
        </DialogTitle>
        <LegalDocumentDialogContent
            html={document.body ?? ''}
            onBottomScroll={onBottomScroll}
        />
        <DialogActions>
            <DialogActionsWrapper>
                <Button onClick={document.closeDialog} text='Close' />
            </DialogActionsWrapper>
        </DialogActions>
    </Dialog>
);
