import { getBaseUrl } from '../Services/API/url-config';
import { useApi } from './useApi';

export const useEndpoints = () => {
    const { callApi } = useApi({ baseURL: getBaseUrl() });

    const getRegistrationInvites = async (token: string) => {
        return await callApi<CogApi.IValidateTokenResponse>({
            method: 'GET',
            url: `registrationinvites/${token}`,
        });
    };

    const setNewPassword = async ({
        data,
        token,
    }: {
        data: CogApi.ISetPasswordRequest;
        token: string;
    }) => {
        return await callApi<
            CogApi.ISetPasswordResponse,
            CogApi.ISetPasswordRequest
        >({
            method: 'POST',
            url: `registrationinvites/${token}`,
            data,
        });
    };

    const getAppConfig = async () => {
        return await callApi<CogApi.IAppConfigResponse>({
            url: '/config',
            method: 'GET',
        });
    };

    const validateEmail = async (email: string, token?: string) => {
        return await callApi<CogApi.IValidateEmailResponse>({
            method: 'GET',
            url: `auth/${encodeURIComponent(email)}?token=${token}`,
        });
    };

    const resendRegistrationInvite = async (
        clientId: string,
        loginId: string,
        hasLearnerRole: boolean
    ) => {
        await callApi({
            method: 'POST',
            url: `registrationinvites/${clientId}/${loginId}/registration?hasLearnerRole=${hasLearnerRole}`,
        });
    };

    const getLegalDocuments = async () => {
        return await callApi<CogApi.ILatestLegalDocumentsResponse>({
            method: 'GET',
            url: 'legaldocuments/latest',
        });
    };

    const getSignupData = async (friendlyId: string) => {
        return await callApi<CogApi.ISignupDataRespons>({
            method: 'GET',
            url: `signups/${friendlyId}`,
        });
    };

    return {
        getRegistrationInvites,
        getAppConfig,
        validateEmail,
        setNewPassword,
        getLegalDocuments,
        getSignupData,
        resendRegistrationInvite,
    };
};
