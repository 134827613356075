import {
    Button,
    DialogTitleProps,
    FormControlLabel,
    IconButton,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogTitle as MuiDialogTitle,
    TextField,
    styled,
} from '@mui/material';

import { Checkbox } from '@cognassist/react-components';
import { pxToRem } from '../../utils/pxToRem';
import { shouldForwardProps } from '../../utils/shouldForwardProp';

export const Wrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
}));
export const Signup = styled('div')(({ theme }) => ({
    flex: '1 0 600px',
    padding: '52px 62px',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down(744)]: {
        padding: '24px',
        flex: '1 1 600px',
    },
}));
export const Form = styled('div')(() => ({
    maxWidth: 394,
    margin: 'auto',
    marginTop: 72,
}));
export const Carousel = styled('div')(({ theme }) => ({
    flex: '1 1 60%',
    background:
        'linear-gradient(45deg, rgba(193,235,252,1) 0%, rgba(233,249,197,1) 100%)',
    [theme.breakpoints.down(744)]: {
        display: 'none',
    },
}));

export const Logo = styled('img')(() => ({
    height: 36,
}));
export const Header = styled('h1')(({ theme }) => ({
    fontWeight: 'normal',
    fontSize: pxToRem(36),
    lineHeight: pxToRem(40),
    maxWidth: 200,
    margin: 0,
    marginBottom: 24,
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(32),
        lineHeight: pxToRem(35),
    },
}));
export const SubHeader = styled('h2')(({ theme }) => ({
    fontSize: pxToRem(24),
    lineHeight: pxToRem(26),
    fontWeight: 'normal',
    margin: 0,
    marginBottom: 24,
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(20),
    },
}));

export const InputLabel = styled('label')(({ theme }) => ({
    display: 'block',
    fontSize: pxToRem(16),
    lineHeight: pxToRem(18),
    [theme.breakpoints.down(744)]: {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(15),
    },
}));

export const InputLabelWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
}));
export const InputWrapper = styled('div')(() => ({
    marginBottom: '16px',
}));
export const Input = styled(TextField)(() => ({
    marginBottom: '16px',
    '& .MuiOutlinedInput-root': {
        height: 45,
        '& fieldset': {
            borderColor: '#CFD8E6',
            borderWidth: 2,
        },
        '&:hover fieldset': {
            borderWidth: 1,
        },
        '&.Mui-focused fieldset': {
            borderWidth: 1,
        },
    },
}));

export const TermsConditionsWrapper = styled('div')(() => ({
    marginTop: 24,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& span': {
        fontSize: pxToRem(14),
        lineHeight: pxToRem(22),
    },
}));

export const TermsConditionsLabel = styled(FormControlLabel)(() => ({
    marginLeft: 0,
    display: 'flex',
    alignItems: 'flex-start',
}));

export const TermsConditionsCheckbox = styled(Checkbox)(() => ({
    marginRight: 10,
}));

export const LegalDocumentName = styled('span')(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const Required = styled('span', {
    ...shouldForwardProps('textColor'),
})<{ textColor: string }>(({ textColor }) => ({
    color: textColor,
    fontWeight: 'bold',
}));

export const ShowPasswordButton = styled('button')(() => ({
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontSize: pxToRem(12),
    fontWeight: 'bold',
    color: '#33383F',
}));

export const SignUpButton = styled(Button)(({ theme }) => ({
    marginTop: 25,
    padding: '12px 30px !important',
    fontSize: pxToRem(14),
    textTransform: 'none',
    fontWeight: 'bold',
    [theme.breakpoints.down(744)]: {
        width: '100%',
    },
}));

export const BottomNav = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(25),
    '& > a, & > p': {
        margin: '0 10px',
        color: '#33383F ',
    },
    '& > a': {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    [theme.breakpoints.down(744)]: {
        justifyContent: 'center',
    },
}));

export const CarouselWrapper = styled('div')(() => ({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: '400px',
    // margin: '0 auto',
}));

export const CarouselItem = styled('div')(() => ({
    minHeight: '500px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    paddingBottom: '24px',
}));

export const CarouselImage = styled('img')(() => ({
    // width: '450px !important',
    marginBottom: 32,
    // transform: 'scale(0.5)',
}));

export const CarouselHeading = styled('h2')(() => ({
    fontSize: pxToRem(24),
    lineHeight: pxToRem(26),
    fontWeight: 'normal',
    marginBottom: 40,
}));

export const CarouselText = styled('p')(() => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    marginBottom: 24,
    maxWidth: 520,
}));

export const CarouselArrow = styled('button')(() => ({
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 28,
    height: 28,
    cursor: 'pointer',
    borderRadius: 50,
    border: 'none',
    boxShadow: '0px 0px 8px rgba(207, 216, 229, 0.25)',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const Dialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        minWidth: 500,
        [theme.breakpoints.down('sm')]: {
            minWidth: 0,
        },
    },
}));

export const DialogActions = styled(MuiDialogActions)(() => ({
    margin: 0,
    padding: '18px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const DialogContent = styled(MuiDialogContent)(() => ({
    padding: '16px 24px',
    lineHeight: '24px',
    '& p:first-child': {
        margin: 0,
    },
    '& p:last-child': {
        marginBottom: 0,
    },
}));

export const DialogTitle = styled(MuiDialogTitle)<DialogTitleProps>(() => ({
    margin: 0,
    padding: '17px 24px',
    '& h1': {
        lineHeight: pxToRem(22),
    },
}));

export const DialogActionsWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
}));

export const DialogCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: 24,
    top: 17,
    padding: 0,
    color: theme.palette.grey[500],
}));
