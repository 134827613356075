import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';

import { useConfig } from './ConfigProvider';

interface IOwnProps {
    children: React.ReactNode;
}

export const GrowthbookProvider: React.FC<IOwnProps> = ({ children }) => {
    const {
        state: {
            appConfig: { growthbookKey, environment },
        },
    } = useConfig();

    const [growthbook, setGrowthbook] = useState<GrowthBook>();

    useEffect(() => {
        if (growthbookKey) {
            const gb = new GrowthBook({
                apiHost: 'https://cdn.growthbook.io',
                clientKey: growthbookKey,
                enableDevMode: environment === 'local',
                trackingCallback: (experiment, result) => {
                    console.log('Viewed Experiment', {
                        experimentId: experiment.key,
                        variationId: result.variationId,
                    });
                },
            });

            setGrowthbook(gb);
        }
    }, [growthbookKey, environment]);

    useEffect(() => {
        if (growthbook) {
            growthbook.loadFeatures();
        }
    }, [growthbook]);

    return (
        <GrowthBookProvider growthbook={growthbook}>
            {children}
        </GrowthBookProvider>
    );
};
