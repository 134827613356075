import { ArrowLeft } from 'react-feather';
import { Button } from '@cognassist/react-components';
import Footer from '../components/footer';
import imgWarning from '../assets/img/warning.png';
import { pxToRem } from '../utils/pxToRem';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PageWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
}));

const Wrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    textAlign: 'center',
    '& h1': {
        marginTop: 24,
        textalign: 'center',
    },
    '& p': {
        marginTop: 24,
        textalign: 'center',
    },
}));

const LargeTitle = styled('h1')(() => ({
    fontSize: pxToRem(100),
    linHeight: pxToRem(110),
    marginTop: '50px !important',
    marginBottom: 20,
}));

export const NotFound: React.FC = () => {
    const navigate = useNavigate();
    return (
        <PageWrapper>
            <Wrapper>
                <img src={imgWarning} alt='Triangle with exclaimation point' />
                <LargeTitle>404</LargeTitle>
                <h2>Something went wrong</h2>
                <p>Sorry we were unable to find that page.</p>
                <Button
                    size='large'
                    sx={{ mt: 3 }}
                    onClick={() => navigate(-1)}
                    text='Go back'
                    startIcon={<ArrowLeft />}
                />
                <Footer />
            </Wrapper>
        </PageWrapper>
    );
};

export default NotFound;
