import { useEffect, useRef } from 'react';
import { DialogContent } from './styled-components';
import { useHasScrolledToBottom } from '../../custom-hooks/useHasScrolledToBottom';

interface IOwnProps {
    html: string;
    onBottomScroll: () => void;
}

export const LegalDocumentDialogContent: React.FC<IOwnProps> = ({
    html,
    onBottomScroll,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const isOnBottom = useHasScrolledToBottom(ref);

    useEffect(() => {
        if (isOnBottom) {
            onBottomScroll();
        }
    }, [isOnBottom]);

    return (
        <DialogContent dividers ref={ref}>
            <div
                dangerouslySetInnerHTML={{
                    __html: html,
                }}
            />
        </DialogContent>
    );
};
