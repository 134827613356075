import { useEffect, useState } from 'react';

import Loading from './Loading';
import { useEndpoints } from '../custom-hooks/useEndpoints';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export const SelfOnboardingRedirect = () => {
    const { friendlyId } = useParams<{ friendlyId: string }>();
    const { getSignupData } = useEndpoints();

    const [loading, setLoading] = useState<boolean>(true);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (friendlyId) {
            getData(friendlyId);
        }
    }, [friendlyId]);

    const getData = async (id: string) => {
        const { data, error } = await getSignupData(id);

        if (error) {
            enqueueSnackbar('Something went wrong', {
                variant: 'error',
            });
        } else if (data) {
            if (window.location.hostname === 'localhost') {
                setLoading(false);
                window.location.href = `http://localhost:64890/signup/${friendlyId}`;
            } else {
                const vanity = data.vanitySubDomain || '';
                setLoading(false);
                window.location.href = `https://${vanity}.${window.location.host}/signup/${friendlyId}`;
            }
        }
    };

    if (loading) {
        return <Loading />;
    }

    return null;
};
