import { createStyles, makeStyles } from '@mui/styles';

import beigeSemiCircle from '../assets/img/beige-semi-circle.svg';
import donut from '../assets/img/donut.svg';
import greenSemiCircle from '../assets/img/green-semi-circle.svg';
import squiggle from '../assets/img/squiggle.svg';
import wave from '../assets/img/wave.svg';

const useStyles = makeStyles(() =>
    createStyles({
        wave: {
            position: 'fixed',
            width: '100%',
            minWidth: 1440,
            bottom: 0,
        },
        squiggle: {
            position: 'fixed',
            right: '5%',
            bottom: 0,
        },
        donut: {
            position: 'fixed',
            top: '13%',
            right: '25%',
            animation: `$pulse 7000ms linear infinite`,
        },
        greenSemiCircle: {
            position: 'fixed',
            left: '22%',
            bottom: '15%',
            animation: `$spin 10000ms linear infinite`,
        },
        beigeSemiCircle: {
            position: 'fixed',
            top: '4%',
            left: '25%',
            animation: `$spin 7000ms linear infinite`,
        },
        '@keyframes pulse': {
            '0%': {
                transform: 'scale(1)',
            },
            '50%': {
                transform: 'scale(1.5)',
            },
            '100%': {
                transform: 'scale(1)',
            },
        },
        '@keyframes wave': {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '25%': {
                transform: 'rotate(1deg)',
            },
            '10%': {
                transform: 'rotate(0deg)',
            },
            '75%': {
                transform: 'rotate(-1deg)',
            },
            '100%': {
                transform: 'rotate(0deg)',
            },
        },
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '100%': {
                transform: 'rotate(360deg)',
            },
        },
    })
);

export const Cogfetti: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <img
                className={classes.greenSemiCircle}
                src={greenSemiCircle}
                alt='green semi circle in top left, in background'
            />
            <img
                className={classes.squiggle}
                src={squiggle}
                alt='yellow zigzag in bottom right, in background'
            />
            <img
                className={classes.wave}
                src={wave}
                alt='grey wave across bottom in background'
            />
            <img
                className={classes.donut}
                src={donut}
                alt='purple donut in bottom right, in background'
            />
            <img
                className={classes.beigeSemiCircle}
                src={beigeSemiCircle}
                alt='beige semi circle in bottom left, in background'
            />
        </>
    );
};

export default Cogfetti;
