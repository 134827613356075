import React from 'react';
import { pxToRem } from '../../utils/pxToRem';
import { styled } from '@mui/material';

const Wrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
    width: 'calc(100% - 60px)',
    zIndex: 999,
    margin: '0 30px',
    [theme.breakpoints.down(480)]: {
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        margin: '0 auto 8px',
        width: '100%',
    },
}));

const LeftWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1 0 175px',
    [theme.breakpoints.down(480)]: {
        flex: '0 0 100%',
        width: '100%',
        justifyContent: 'space-around',
    },
}));

const Privacy = styled('a')(({ theme }) => ({
    fontSize: pxToRem(16),
    color: theme.palette.primary.main,
    marginRight: 20,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(480)]: {
        fontSize: pxToRem(16),
        color: theme.palette.common.black,
        marginRight: 0,
    },
}));

const Support = styled('a')(({ theme }) => ({
    fontSize: pxToRem(16),
    color: theme.palette.primary.main,
    [theme.breakpoints.down(480)]: {
        fontSize: pxToRem(16),
        color: theme.palette.common.black,
    },
}));

const Copyright = styled('div')(({ theme }) => ({
    fontSize: pxToRem(16),
    color: theme.palette.text.primary,
    flex: '0 0 175px',
    textAlign: 'right',
    [theme.breakpoints.down(480)]: {
        fontSize: pxToRem(16),
        color: theme.palette.common.black,
        flex: '0 0 100%',
        textAlign: 'center',
    },
}));

const Footer: React.FC = () => {
    const DATE = new Date();

    return (
        <Wrapper>
            <LeftWrapper>
                <div>
                    <Privacy
                        className='privacy'
                        href='https://cognassist.com/privacy-policy/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Privacy Policy
                    </Privacy>
                </div>
                <div>
                    <Support
                        className='support'
                        href='https://cognassist.force.com/support'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Support
                    </Support>
                </div>
            </LeftWrapper>
            <Copyright>&copy;{DATE.getFullYear()} Cognassist</Copyright>
        </Wrapper>
    );
};

export default Footer;
