import { createStyles, makeStyles } from '@mui/styles';

import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        passwordStrengthBarLabel: {
            fontWeight: 500,
            fontSize: 12,
            color: theme.palette.text.primary,
            flexShrink: 0,
            margin: 0,
        },
        passwordStrengthBarSpan: {
            fontWeight: 700,
        },
    })
);

interface IOwnProps {
    passwordStrength: number;
}

const PasswordStrengthLabel: React.FC<IOwnProps> = ({ passwordStrength }) => {
    const classes = useStyles();

    const getLabel = (passwordStrength: number) => {
        switch (passwordStrength) {
            case 1:
                return 'Too Weak';
            case 2:
                return 'Too Weak';
            case 3:
                return 'Average';
            case 4:
                return 'Strong';
            case 5:
                return 'Strong';
            default:
                return 'Too Weak';
        }
    };

    return (
        <p className={classes.passwordStrengthBarLabel}>
            {'Password strength: '}
            <span
                data-testid='passwordStrengthLabel'
                className={classes.passwordStrengthBarSpan}
            >
                {getLabel(passwordStrength)}
            </span>
        </p>
    );
};

export default PasswordStrengthLabel;
