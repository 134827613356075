import React, { useContext, useEffect, useMemo } from 'react';

import { useEndpoints } from '../custom-hooks/useEndpoints';
import { useImmerReducer } from 'use-immer';

export const initialState = {
    appConfig: {
        environment: undefined,
        growthbookKey: undefined,
    },
};

export const reducer = (
    state: CogConfig.State,
    action: CogConfig.Action
): void => {
    if (action.type === 'SET_APP_CONFIG') {
        state.appConfig = {
            ...state.appConfig,
            ...action.payload,
        };
    }
};

export const ConfigContext = React.createContext<CogConfig.IConfigContext>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const ConfigProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<
        CogConfig.State,
        CogConfig.Action
    >(reducer, initialState);

    const val = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state, dispatch]
    );

    const { getAppConfig } = useEndpoints();

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = async () => {
        const { data } = await getAppConfig();

        if (data) {
            dispatch({
                type: 'SET_APP_CONFIG',
                payload: {
                    environment: data.environment,
                    growthbookKey: data.growthbookKey,
                    applicationInsightsConnectionString:
                        data.applicationInsightsConnectionString,
                },
            });
        }
    };

    return (
        <ConfigContext.Provider value={val}>{children}</ConfigContext.Provider>
    );
};

export const useConfig = (): CogConfig.IConfigContext =>
    useContext(ConfigContext);
