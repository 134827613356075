import {
    TermsConditionsWrapper,
    TermsConditionsLabel,
    TermsConditionsCheckbox,
    LegalDocumentName,
    Required,
} from './styled-components';
import { TrackingEvent } from '../../types/tracking-events';
import { LegalDocumentType } from '../../types/enums';
import { useTheme } from '@mui/material/styles';

type LegalDocument = {
    id?: string;
    body?: string;
    legalDocumentType?: LegalDocumentType;
    name: string;
    dialogOpen: boolean;
    openDialog: () => void;
    closeDialog: () => void;
    scrollTrackingEvent: TrackingEvent;
};

interface IOwnProps {
    legalDocuments: LegalDocument[];
    acceptTermsAndConditions: boolean;
    setAcceptTermsAndConditions: React.Dispatch<React.SetStateAction<boolean>>;
    termsAndConditionsError: boolean;
}

export const TermsConditions: React.FC<IOwnProps> = ({
    legalDocuments,
    acceptTermsAndConditions,
    setAcceptTermsAndConditions,
    termsAndConditionsError,
}) => {
    const theme = useTheme();

    const TermsConditionsLabelDetails = (
        <>
            I accept the{' '}
            {legalDocuments.map((document, index) => (
                <span key={document.name}>
                    <LegalDocumentName
                        role='button'
                        tabIndex={0}
                        onClick={(e) => {
                            e.preventDefault();
                            document.openDialog();
                        }}
                    >
                        {document.name}
                    </LegalDocumentName>
                    {index !== legalDocuments.length - 1 ? ' and ' : '.'}
                </span>
            ))}
        </>
    );

    return (
        <TermsConditionsWrapper>
            <TermsConditionsLabel
                control={
                    <TermsConditionsCheckbox
                        checked={acceptTermsAndConditions}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAcceptTermsAndConditions(e.target.checked)
                        }
                    />
                }
                label={TermsConditionsLabelDetails}
            />
            <Required
                textColor={
                    termsAndConditionsError
                        ? theme.palette.error.main
                        : theme.palette.grey[500]
                }
            >
                Required
            </Required>
        </TermsConditionsWrapper>
    );
};
